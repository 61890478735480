import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  titleInArticle,
  featuredimage,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  const tagPath = `/${kebabCase(tags[0])}/`
  return (
    <>
      {helmet || ''}
      <header>
        <div className="head-text-box">
          <div className="head-text">
            <h1 className="main-h-text-1 white"><strong>{titleInArticle.split(':')[0]} :</strong> {titleInArticle.split(':')[1]}</h1>
            <div className="head-bread-contain">
              <div className="head-bread-text-contain">
                <div className="head-bread-text" dangerouslySetInnerHTML={{ __html: description }}>
                  
                </div>
              </div>
              <div className="head-bread-image">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                    alt: `Image pour l'article ${title}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </header> 
      <div className="breadcrumbs transparent">
        <div className="breadcrumbs-links">
          <Link className="bread-item" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M21 12v10h-6v-6H9v6H3V12H0L12 0l12 12zm-1-5.907V1h-3v2.093z"></path></svg>
          </Link>
           ❯
          <Link className="bread-item" to={tagPath}>{tags[0]}</Link>
           ❯ 
           <Link className="bread-item on" to="#">{titleInArticle}</Link>
        </div>
      </div>
      <main>
        {/* <h1>Long title which is very long to see what happens if it gets long</h1> */}
        <div className="main-wrapper">
          <section className="main-section">
          <PostContent content={content} />
          </section>
        </div> 
      </main>
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  featuredimage: PropTypes.object,
  titleInArticle: PropTypes.string
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.fields.frontmattermd.description.html}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        titleInArticle={post.frontmatter.titleInArticle}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        titleInArticle
        featuredimage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
          extension
          publicURL
        }
      }
      fields {
        frontmattermd {
          description {
            html
          }
        }
      }
    }
  }
`
